import { DEFAULT_ASYNC_STATE } from '../constants';
import {
  GET_SLACK_USER_FULFILLED,
  GET_SLACK_USER_PENDING,
  GET_SLACK_USER_REJECTED
} from '../actions/types';

const DEFAULT_STATE = {
  ...DEFAULT_ASYNC_STATE,
  user: {}
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case GET_SLACK_USER_PENDING:
      return {
        ...DEFAULT_STATE,
        isPending: true
      };
    case GET_SLACK_USER_REJECTED:
      return {
        ...DEFAULT_STATE,
        isRejected: true,
        error: action.payload
      };
    case GET_SLACK_USER_FULFILLED:
      return {
        ...DEFAULT_STATE,
        isFulfilled: true,
        user: action.payload.user
      };
    default:
      return state;
  }
};
