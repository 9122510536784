import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, FormGroup, Input, Label, Button } from 'reactstrap';

const StandUpForm = () => {
  const user = useSelector(state => state.profile.current.user);

  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(0);

  const questions = projects[selectedProject]?.questions;
  const submitForm = event => {
    event.preventDefault();
    const data = new FormData(event.target);
    const answers = [];
    questions.forEach((q, i) => {
      answers.push({ question: q, answer: data.get(q) });
    });
    const req = {
      projectId: projects[data.get('project')]._id,
      responder: { name: user.name },
      answers
    };
    console.log(req);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(req)
    };
    // eslint-disable-next-line no-undef
    return fetch(
      `${process.env.REACT_APP_API_URL}/auth/responses`,
      requestOptions
    )
      .then(res => res.json())
      .then(json => {
        if (json.data) {
          alert('Your response was submitted successfully!');
        } else {
          alert('Something went wrong.');
        }
      })
      .catch(e => {
        alert('Error:', e);
        return e;
      });
  };

  useEffect(() => {
    const requestOptions = {
      method: 'GET'
    };
    // eslint-disable-next-line no-undef
    return fetch(
      `${process.env.REACT_APP_API_URL}/auth/projects`,
      requestOptions
    )
      .then(res => res.json())
      .then(json => {
        setProjects(json.data);
      })
      .catch(e => {
        return e;
      });
  }, []);

  return (
    <div>
      <h2>
        Hello,
        {user ? ` ${user.real_name}` : ''}
      </h2>
      <p>Please submit your standup: </p>
      <Form onSubmit={submitForm}>
        <FormGroup>
          <Label for="project">Select project:</Label>
          <Input
            type="select"
            name="project"
            id="project"
            onChange={e => setSelectedProject(e.target.value)}
          >
            {projects.map((p, i) => {
              return <option value={i}>{p.name}</option>;
            })}
          </Input>
        </FormGroup>
        {questions?.map(q => {
          return (
            <FormGroup>
              <Label for={q}>{q}</Label>
              <Input type="text" name={q} id={q} />
            </FormGroup>
          );
        })}
        <Button type="submit">Submit</Button>
      </Form>
    </div>
  );
};

export default StandUpForm;
