import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import LogRocket from 'logrocket';
import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';
import reducer from './reducers';

const loggerMiddleware = createLogger({
  level: 'error',
  collapsed: true
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(
  applyMiddleware(
    promiseMiddleware,
    thunkMiddleware,
    loggerMiddleware,
    LogRocket.reduxMiddleware()
  )
);

export default function configureStore(initialState) {
  return createStore(reducer, initialState, enhancer);
}
