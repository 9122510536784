import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage, getIn } from 'formik';
import { Button, FormGroup, Label, Input } from 'reactstrap';
import validationSchema from './validationSchema';

class ForgotPasswordForm extends PureComponent {
  renderInput = props => {
    const {
      field,
      field: { name },
      placeholder,
      type,
      id,
      label,
      form: { errors, touched }
    } = props;
    const hasError = getIn(errors, name) && getIn(touched, name);

    return (
      <FormGroup>
        <Label for={id} className="d-none">
          {label}
        </Label>
        <Input
          {...field}
          type={type}
          placeholder={placeholder}
          id={id}
          invalid={hasError}
        />
        {this.renderInputErrors(name)}
      </FormGroup>
    );
  };

  renderInputErrors = inputName => (
    <ErrorMessage name={inputName}>
      {msg => (
        <div className="errorContainer">
          <span className="errorMessage">{msg}</span>
        </div>
      )}
    </ErrorMessage>
  );

  renderSubmitButton = ({ isSubmitting }) => {
    const { isPending } = this.props;
    return (
      <Button
        disabled={isSubmitting && isPending}
        type="submit"
        color="primary"
        className="btn-block mb-3 mt-4"
      >
        Reset password
      </Button>
    );
  };

  renderFooter = () => (
    <>
      <div className="d-flex justify-content-end small">
        <span>
          Don&apos;t have an account?&nbsp;
          <Link to="/join" className="link">
            Sign up.
          </Link>
        </span>
      </div>
      <div className="text-center small tac fixed-bottom">
        By signing up or logging in, you agree to the barter.io &nbsp;
        <a href="/">Terms of Service</a>
        &nbsp;and&nbsp;
        <a href="/">Privacy Policy</a>
      </div>
    </>
  );

  renderForm = ({ isSubmitting }) => (
    <Form autoComplete="off">
      <Field
        name="email"
        render={props =>
          this.renderInput({
            ...props,
            type: 'email',
            id: 'userEmail',
            placeholder: 'Email address',
            label: 'Email'
          })
        }
      />
      {this.renderSubmitButton({ isSubmitting })}
      {this.renderFooter()}
    </Form>
  );

  render() {
    const { onSubmit } = this.props;
    return (
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        render={this.renderForm}
      />
    );
  }
}

export default ForgotPasswordForm;
