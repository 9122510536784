import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const withTracking = WrappedComponent =>
  class Enhancer extends PureComponent {
    renderLoader = (props = {}) => (
      <div className="loadingIndicator" {...props}>
        <FontAwesomeIcon icon={['fal', 'spinner']} size="4x" spin />
      </div>
    );

    render() {
      return (
        <WrappedComponent {...this.props} renderLoader={this.renderLoader} />
      );
    }
  };

export default withTracking;
