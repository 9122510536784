import React, { useState, useEffect } from 'react';

import {
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import Project from './Project';

const ProjectList = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [newProject, setNewProject] = useState({
    questions: [],
    dayOfWeek: 'MON',
    teamLead: { id: 0, name: '' }
  });
  const [members, setMembers] = useState([]);
  const [projects, setProjects] = useState([]);

  const tlIndex = members.indexOf(newProject.teamLead);
  const editProject = (e, key) => {
    let { value } = e.target;
    if (key === 'teamLead') {
      value = members[e.target.value];
    }
    setNewProject({ ...newProject, [key]: value });
  };

  const addDeleteQuestion = (add, idx) => {
    if (add) {
      const newQuestions = newProject.questions;
      newQuestions.push('');
      console.log(newQuestions);
      setNewProject({
        ...newProject,
        questions: newQuestions
      });
    } else {
      const newQuestions = newProject.questions;
      newQuestions.splice(idx, 1);
      setNewProject({
        ...newProject,
        questions: newQuestions
      });
    }
  };

  const editQuestion = (text, idx) => {
    const newQuestions = newProject.questions;
    newQuestions[idx] = text;
    setNewProject({
      ...newProject,
      questions: newQuestions
    });
  };

  const getUserIdentity = async user => {
    const body = {
      token: localStorage.getItem('access_token'),
      user
    };
    let formBody = [];
    Object.keys(body).forEach(property => {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(body[property]);
      formBody.push(`${encodedKey}=${encodedValue}`);
    });
    formBody = formBody.join('&');
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formBody
    };
    // eslint-disable-next-line no-undef
    return fetch('https://slack.com/api/users.info', requestOptions)
      .then(res => res.json())
      .then(json => {
        return json;
      });
  };

  const getChannelMembers = async () => {
    const body = {
      token: localStorage.getItem('access_token'),
      channel: process.env.REACT_APP_SLACK_CHANNEL
    };
    let formBody = [];
    Object.keys(body).forEach(property => {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(body[property]);
      formBody.push(`${encodedKey}=${encodedValue}`);
    });
    formBody = formBody.join('&');
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formBody
    };
    // eslint-disable-next-line no-undef
    return fetch('https://slack.com/api/conversations.members', requestOptions)
      .then(res => res.json())
      .then(async json => {
        console.log('json', json);
        const m = [];
        m.push({ id: 0, name: 'Select Team Lead' });
        if (json.ok) {
          for (let i = 0; i < json.members.length; i++) {
            const user = await getUserIdentity(json.members[i]);
            console.log(user);
            if (user.ok) {
              m.push({
                id: user.user.id,
                name: user.user.profile.real_name
              });
            }
          }
          setMembers(m);
        }
        return json;
      })
      .catch(e => {
        return e;
      });
  };

  const saveProject = () => {
    if (!newProject.teamLead || newProject.teamLead.id === 0) {
      alert('Team lead must be selected');
    } else {
      const formBody = newProject;
      formBody.channel = {
        id: process.env.REACT_APP_SLACK_CHANNEL,
        name: 'test-update'
      };
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formBody)
      };
      // eslint-disable-next-line no-undef
      return fetch(
        `${process.env.REACT_APP_API_URL}/auth/projects`,
        requestOptions
      )
        .then(res => res.json())
        .then(json => {
          console.log(json);
          toggle();
          getProjects();
        });
    }
  };

  const getProjects = () => {
    const requestOptions = {
      method: 'GET'
    };
    // eslint-disable-next-line no-undef
    return fetch(
      `${process.env.REACT_APP_API_URL}/auth/projects`,
      requestOptions
    )
      .then(res => res.json())
      .then(json => {
        setProjects(json.data);
      });
  };

  useEffect(() => {
    async function fetchData() {
      await getChannelMembers();
      await getProjects();
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Create New Alert</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="name">Alert Title</Label>
              <Input
                type="text"
                value={newProject.name}
                onChange={e => editProject(e, 'name')}
              />
            </FormGroup>
            <FormGroup>
              <Label for="pm">Project Manager</Label>
              <Input
                type="text"
                value={newProject.pm}
                onChange={e => editProject(e, 'pm')}
              />
            </FormGroup>
            <FormGroup>
              <Label for="teamLead">Team Lead:</Label>
              <Input
                type="select"
                value={tlIndex}
                onChange={e => editProject(e, 'teamLead')}
              >
                {members.map((m, idx) => {
                  return <option value={idx}>{m.name}</option>;
                })}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="dayOfWeek">Day of Week:</Label>
              <Input
                type="select"
                value={newProject.dayOfWeek}
                onChange={e => editProject(e, 'dayOfWeek')}
              >
                <option>MON</option>
                <option>TUE</option>
                <option>WED</option>
                <option>THU</option>
                <option>FRI</option>
                <option>SAT</option>
                <option>SUN</option>
              </Input>
            </FormGroup>
            <Label>Questions:</Label>
            <br />
            {newProject.questions.map((q, i) => {
              return (
                <FormGroup>
                  <Label onClick={() => addDeleteQuestion(false, i)} for={q.id}>
                    #{i + 1}
{' '}
- Click to delete
</Label>
                  <Input
                    onChange={e => editQuestion(e.target.value, i)}
                    type="text"
                    value={q}
                  />
                </FormGroup>
              );
            })}
            <Button onClick={() => addDeleteQuestion(true)}>
              Add Question
            </Button>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" type="submit" onClick={saveProject}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
      <Button className="mb-4" onClick={toggle}>
        {' '}
        Add Project{' '}
      </Button>
      <h2>Project List</h2>
      {projects.map(p => (
        <Project info={p} members={members} key={p._id} reload={getProjects} />
      ))}
    </div>
  );
};

export default ProjectList;
