import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import withLoader from '../../components/HOC/withLoader';
import { loginSlack } from '../../store/actions/auth';

class Login extends PureComponent {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    currentUser: PropTypes.shape({
      isRejected: PropTypes.bool.isRequired,
      isPending: PropTypes.bool.isRequired,
      error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          message: PropTypes.string.isRequired
        })
      ])
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    currentProfile: PropTypes.shape({
      data: PropTypes.object
    }).isRequired
  };

  componentDidMount() {
    const { code } = queryString.parse(window.location.search);
    console.log(code);
    // eslint-disable-next-line react/destructuring-assignment
    this.props.dispatch(loginSlack(code));
  }

  /**
   * Handle login form submission
   *
   * @param {Object} credentials - User login and password to sign-in
   * @param {string} credentials.identity - User identity, could be email or username
   * @param {string} credentials.password - User password
   * @memberof Login
   */
  // handleSubmit = async credentials => {
  //   const { signIn, history, getAuthUser } = this.props;
  //   try {
  //     await signIn(credentials);
  //     await getAuthUser();
  //     history.push(LOGGED_IN_REDIRECT_URL);
  //   } catch (err) {
  //     console.log(err);
  //     throw err;
  //   }
  // };

  // renderFooter = () => (
  //   <>
  //     <div className="d-flex justify-content-between small">
  //       <span>
  //         <Link to="/forgot-password" className="link">
  //           Forgot password?
  //         </Link>
  //       </span>
  //       <span>
  //         Don&apos;t have an account?&nbsp;
  //         <Link to="/join" className="link">
  //           Sign up.
  //         </Link>
  //       </span>
  //     </div>
  //     <div className="text-center small tac fixed-bottom">
  //       By signing up or logging in, you agree to the barter.io &nbsp;
  //       <a href="/">Terms of Service</a>
  //       &nbsp;and&nbsp;
  //       <a href="/">Privacy Policy</a>
  //     </div>
  //   </>
  // );

  render() {
    const {
      currentUser: { isAuthenticated }
    } = this.props;

    return (
      <div>
        {isAuthenticated ? (
          <div>
            Your authentication with Slack was successful!
{' '}
            <a href="/form">Proceed to form</a>
{' '}
          </div>
        ) : (
          'An error occurred when attempting to login with Slack'
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
  currentProfile: state.profile.fetch.data
});

// const mapDispatchToProps = dispatch => ({
//   signIn: credentials => dispatch(signInAction(credentials))
// });

export default compose(withLoader, withRouter, connect(mapStateToProps))(Login);
