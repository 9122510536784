// TODO: LAZY LOAD COMPONENTS VIA React.Suspense and React.lazy()

import React from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';

// free-solid-svg-icons import
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faStarHalfAlt } from '@fortawesome/free-solid-svg-icons/faStarHalfAlt';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';

// pro-light-svg-icons import
// import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
// import { faPlusCircle } from '@fortawesome/pro-light-svg-icons/faPlusCircle';
// import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
// import { faCamera } from '@fortawesome/pro-light-svg-icons/faCamera';
// import { faHome } from '@fortawesome/pro-light-svg-icons/faHome';
// import { faArrowCircleUp } from '@fortawesome/pro-light-svg-icons/faArrowCircleUp';
// import { faImages } from '@fortawesome/pro-light-svg-icons/faImages';
// import { faSync } from '@fortawesome/pro-light-svg-icons/faSync';
// import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
// import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
// import { faTimesHexagon } from '@fortawesome/pro-light-svg-icons/faTimesHexagon';
// import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash';
// import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil';
// import { faSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner';
// import { faExchange } from '@fortawesome/pro-light-svg-icons/faExchange';
// import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
// import { faSignIn } from '@fortawesome/pro-light-svg-icons/faSignIn';
// import { faSignInAlt } from '@fortawesome/pro-light-svg-icons/faSignInAlt';

import Login from './views/Login';
import StyleGuide from './views/StyleGuide';
import ForgotPassword from './views/ForgotPassword/ForgotPassword';
import CreateAccount from './views/CreateAccount/CreateAccount';
import EditProfile from './views/EditProfile/EditProfile';
import SignUpConfirm from './views/ConfirmSignUp';
import ForgotPasswordConfirm from './views/ConfirmForgotPassword/ConfirmForgotPassword';
import Home from './views/Home';
import StandUpForm from './views/StandUpForm';
import ProjectList from './views/ProjectList';

import configureStore from './store';
import App from './components/App';
import ProtectedRoute from './components/ProtectedRoute';

library.add(
  faFacebook,
  faGoogle,
  // faPlus,
  // faPlusCircle,
  // faTimesHexagon,
  // faArrowCircleUp,
  // faCheck,
  // faSync,
  // faHome,
  // faTimes,
  // faImages,
  // faSearch,
  // faCamera,
  faStar,
  faStarHalfAlt,
  faCheckCircle,
  faAngleRight
  // faSpinner,
  // faTrash,
  // faPencil,
  // faExchange,
  // faChevronRight,
  // faSignIn,
  // faSignInAlt
);

const history = createBrowserHistory();
const store = configureStore();

const Root = () => (
  <Provider store={store}>
    <Router history={history}>
      <App>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/index.html" component={Home} />
          <Route exact path="/styleguide" component={StyleGuide} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/join" component={CreateAccount} />
          <Route path="/signup-confirm/:code?" component={SignUpConfirm} />
          <Route
            path="/forgot-password-confirm/:code?"
            component={ForgotPasswordConfirm}
          />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/form" component={StandUpForm} />
          <Route path="/projects" component={ProjectList} />
          <ProtectedRoute exact path="/edit-profile" component={EditProfile} />
        </Switch>
      </App>
    </Router>
  </Provider>
);

export default Root;
