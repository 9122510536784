export default {
  auth: {
    verifyUser: async token => {
      const details = {
        token
      };
      let formBody = [];
      Object.keys(details).forEach(property => {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(details[property]);
        formBody.push(`${encodedKey}=${encodedValue}`);
      });
      formBody = formBody.join('&');
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formBody
      };
      // eslint-disable-next-line no-undef
      return fetch('https://slack.com/api/auth.test', requestOptions)
        .then(res => res.json())
        .then(json => {
          return json;
        })
        .catch(e => {
          return e;
        });
    },

    loginSlack: async code => {
      const details = {
        code,
        redirect_uri: process.env.REACT_APP_REDIRECT_URI,
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET
      };
      let formBody = [];
      Object.keys(details).forEach(property => {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(details[property]);
        formBody.push(`${encodedKey}=${encodedValue}`);
      });
      formBody = formBody.join('&');

      // const encodedString = window.btoa(
      //   `${process.env.REACT_APP_CLIENT_ID}:${process.env.REACT_APP_CLIENT_SECRET}`
      // );

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
          // Authentication: `Basic ${encodedString}`
        },
        body: formBody
      };
      // eslint-disable-next-line no-undef
      return fetch('https://slack.com/api/oauth.v2.access', requestOptions)
        .then(res => res.json())
        .then(json => {
          console.log('json', json);
          return json;
        })
        .catch(e => {
          return e;
        });
    },

    getUser: async (token, user) => {
      const details = {
        token,
        user
      };
      let formBody = [];
      Object.keys(details).forEach(property => {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(details[property]);
        formBody.push(`${encodedKey}=${encodedValue}`);
      });
      formBody = formBody.join('&');
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formBody
      };
      // eslint-disable-next-line no-undef
      return fetch('https://slack.com/api/users.info', requestOptions)
        .then(res => res.json())
        .then(json => {
          console.log('json', json);
          return json;
        })
        .catch(e => {
          return e;
        });
    }
  }
};
