import React, { PureComponent } from 'react';
import { Button, FormGroup, Label, Input, Alert } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage, getIn } from 'formik';
import validationSchema from './validationSchema';

class ConfirmationForm extends PureComponent {
  renderInputErrors = inputName => (
    <ErrorMessage name={inputName}>
      {msg => (
        <div className="errorContainer">
          <span className="errorMessage">{msg}</span>
        </div>
      )}
    </ErrorMessage>
  );

  renderInput = props => {
    const {
      field,
      field: { name },
      placeholder,
      type,
      id,
      label,
      className = '',
      disabled = '',
      form: { errors, touched }
    } = props;
    const hasError = getIn(errors, name) && getIn(touched, name);

    return (
      <FormGroup className={className || 'col-12'}>
        <Label for={id} className="d-none">
          {label}
        </Label>
        <Input
          {...field}
          type={type}
          placeholder={placeholder}
          id={id}
          invalid={hasError}
          disabled={disabled}
        />
        {this.renderInputErrors(name)}
      </FormGroup>
    );
  };

  renderSubmitButton = ({ isSubmitting }) => {
    const { isPending } = this.props;
    return (
      <Button
        disabled={isSubmitting && isPending}
        type="submit"
        color="primary"
        className="btn-block"
      >
        Confirm!
      </Button>
    );
  };

  renderForm = ({ isSubmitting }) => (
    <Form className="row">
      <div className="container">
        <Field
          name="userName"
          render={props => {
            const {
              initialValues: { username = '' }
            } = this.props;
            return this.renderInput({
              ...props,
              type: 'text',
              id: 'userName',
              placeholder: 'Username',
              label: 'Username',
              disabled: username
            });
          }}
        />
        <Field
          name="password"
          render={props => {
            const {
              initialValues: { password = '' }
            } = this.props;
            return this.renderInput({
              ...props,
              type: 'password',
              id: 'password',
              placeholder: 'Password',
              label: 'Password',
              disabled: password
            });
          }}
        />
        <Field
          name="code"
          render={props =>
            this.renderInput({
              ...props,
              type: 'text',
              id: 'confirmationCode',
              placeholder: 'Confirmation code',
              label: 'Confirmation code'
            })
          }
        />

        {this.renderSubmitButton({ isSubmitting })}
      </div>
    </Form>
  );

  render() {
    const {
      onSubmit,
      initialValues: { username, email, password, code }
    } = this.props;

    return (
      <div>
        {code.length === 0 && (
          <Alert color="info">
            An confirmation email has been sent to the email address you
            privided. Please enter the verification code that was included in
            that email below to confirm your email address.
          </Alert>
        )}
        <Formik
          initialValues={{
            code,
            userName: username || '',
            password: password || '',
            email: email || ''
          }}
          onSubmit={onSubmit}
          render={this.renderForm}
          validationSchema={validationSchema}
        />
      </div>
    );
  }
}

export default ConfirmationForm;
