import React from 'react';
import ReactDOM from 'react-dom';
import * as LogRocket from 'logrocket';

import Root from './Root';
import * as serviceWorker from './serviceWorker';
import './styles/index.css';

if (window.location.hostname.indexOf('localhost') < 0) {
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID);
}

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
