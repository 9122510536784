import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';

import withLoader from '../../components/HOC/withLoader';
import { fetchAllProfilesAction } from '../../store/actions/user';

class HomeView extends PureComponent {
  constructor(props) {
    super(props);

    // eslint-disable-next-line react/no-unused-state
    this.state = { position: undefined };
  }

  getCurrentUserLocation() {
    return new Promise((resolve, reject) => {
      try {
        navigator.geolocation.getCurrentPosition(
          p => {
            this.setState({
              // eslint-disable-next-line react/no-unused-state
              position: {
                lat: p.coords.latitude,
                lng: p.coords.longitude
              }
            });

            resolve();
          },
          error => {
            reject(error);
          }
        );
      } catch (err) {
        reject(err);
      }
    }).catch(err => {
      // do nothing for now. Just falls back to
      // normal trending functionality.
      // eslint-disable-next-line no-console
      console.log(err);
    });
  }

  render() {
    return (
      <div>
        <Alert>
          {' '}
          Welcome to Standup Bot! Please log in and navigate to the form or
          project list from the navbar.
{' '}
        </Alert>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profiles: state.profile.all
});

const mapDispatchToProps = dispatch => ({
  getAllUserProfiles: () => dispatch(fetchAllProfilesAction())
});

export default compose(
  withRouter,
  withLoader,
  connect(mapStateToProps, mapDispatchToProps)
)(HomeView);
