export const DEFAULT_ASYNC_STATE = {
  isPending: false,
  isRejected: false,
  isFulfilled: false
};

export const SUCCESSFUL_SIGNUP_FEDERATED_REDIRECT = '/';
export const SUCCESSFUL_SIGNUP_CONFIRM_REDIRECT = '/login';
export const SUCCESSFUL_SIGNUP_REDIRECT = '/signup-confirm';
export const NON_AUTHORIZED_REDIRECT_URL = '/login';
export const LOGGED_IN_REDIRECT_URL = '/';
export const TRENDING_REDIRECT_URL = '/';
export const SIGNUP_REDIRECT_URL = '/join';

export const FEDERATED = {
  GOOGLE: process.env.REACT_APP_AMPLIFY_AUTH_FEDERATED_GOOGLE,
  FACEBOOK: process.env.REACT_APP_AMPLIFY_AUTH_FEDERATED_FACEBOOK
};

export const FEDERATED_STATE = {
  USER_POOL_SIGN_IN: 'userPoolSignIn',
  SOCIAL_SIGN_IN: 'socialSignIn'
};

export const SOCIAL_BUTTON = {
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
  OTHER: 'other'
};

export const ENDPOINT_TYPE = {
  PUBLIC: 'public',
  AUTH: 'auth'
};

export const ALLOWED_IMAGE_DIMENSIONS = {
  MAX_HEIGHT: 2500,
  MAX_WIDTH: 1900,
  MIN_HEIGHT: 250,
  MIN_WIDTH: 190
};
