import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { Helmet } from 'react-helmet';

import ConfirmationForm from '../../components/SignUp/Form/Confirmation';
import { confirmSignUpAction, signInAction } from '../../store/actions/auth';
import withLoader from '../../components/HOC/withLoader';
import { LOGGED_IN_REDIRECT_URL } from '../../store/constants';

class ConfirmSignUp extends PureComponent {
  handleSubmit = async values => {
    const { confirmSignUp, history } = this.props;
    const { code, password, userName } = values;
    try {
      await confirmSignUp(userName, password, code);
      await history.push(LOGGED_IN_REDIRECT_URL);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  render() {
    const {
      signUpData: { user: { username, email, password } = {} },
      signUpConfirm: { isRejected, isPending, error: { message } = {} },
      renderLoader,
      match: {
        params: { code = '' }
      }
    } = this.props;

    return (
      <div>
        <Helmet>
          <title>Confirm Email Address</title>
        </Helmet>
        {isRejected && message && <Alert color="danger">{message}</Alert>}
        {isPending && renderLoader()}
        <h1 className="d-none">Sign-up confirmation</h1>
        <div className="row justify-content-md-center">
          <div className="col-lg-6">
            <ConfirmationForm
              initialValues={{ username, email, password, code }}
              onSubmit={this.handleSubmit}
              isPending={isPending}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  signUpData: state.auth.signUp.data,
  signUpConfirm: state.auth.signUpConfirm
});

const mapDispatchToProps = dispatch => ({
  confirmSignUp: (username, password, code) => {
    dispatch(confirmSignUpAction(username, code));
    dispatch(signInAction({ identity: username, password }));
  }
});

export default compose(
  withLoader,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ConfirmSignUp);
