import React from 'react';
import {
  Button,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Label,
  Input,
  FormText
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.scss';

const StyleGuide = () => (
  <div>
    <Helmet>
      <title>Style Guide</title>
    </Helmet>
    <h1>Barter Style Guide</h1>
    <hr />

    <h2>Headings</h2>
    <div className="bd-callout">
      <h1>h1. Heading using Lato</h1>
      <h2>h2. Heading using Lato</h2>
      <h3>h3. Heading using Lato</h3>
      <h4>h4. Heading using Lato</h4>
      <h5>h5. Heading using Lato</h5>
      <h6>h6. Heading using Lato</h6>
    </div>
    <h3>Typography</h3>
    <div className="bd-callout">
      <p className="lead">
        This is a lead paragraph using Lato as a body font. Lorem ivamus
        sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Duis
        mollis, est non commodo luctus.
      </p>
      <p>
        This is body text with
        <mark>highlighted</mark>
        text.
      </p>
      <p>
        <small>This line of text is meant to be treated as fine print.</small>
      </p>
      <p>
        <strong>This line rendered as bold text.</strong>
      </p>
      <p>
        <em>This line rendered as italicized text.</em>
      </p>
      <hr />
      <ul className="list-unstyled">
        <li>This is an unordered list, styled without bullets</li>
        <li>Consectetur adipiscing elit</li>
        <li>Integer molestie lorem at massa</li>
        <li>Facilisis in pretium nisl aliquet</li>
      </ul>
      <hr />
      <ul>
        <li>This is an unordered list styled with bullets</li>
        <li>Purus sodales ultricies</li>
        <li>Vestibulum laoreet porttitor sem</li>
        <li>Ac tristique libero volutpat at</li>
      </ul>
      <ul>
        <li>This is an unordered list styled with bullets</li>
        <li>Purus sodales ultricies</li>
        <li>Vestibulum laoreet porttitor sem</li>
        <li>Ac tristique libero volutpat at</li>
      </ul>
    </div>
    <h3>Buttons</h3>
    <div className="bd-callout">
      <Button color="primary" className="mr-2 mb-2">
        primary
      </Button>
      <Button color="secondary" className="mr-2 mb-2">
        secondary
      </Button>
      <Button color="success" className="mr-2 mb-2">
        success
      </Button>
      <Button color="info" className="mr-2 mb-2">
        info
      </Button>
      <Button color="warning" className="mr-2 mb-2">
        warning
      </Button>
      <Button color="danger" className="mr-2 mb-2">
        danger
      </Button>
      <Button color="link" className="mr-2 mb-2">
        Clear Button Link
      </Button>
      <Button color="link" className="mr-2 mb-2 d-flex align-items-center">
        Clear Button with Icon &nbsp;
        <FontAwesomeIcon icon="plus-circle" size="2x" color="#0094d5" />
      </Button>
    </div>
    <h3>Card</h3>
    <div className="bd-callout">
      <div>
        <CardImg
          top
          width="100%"
          src="https://placeholdit.imgix.net/~text?txtsize=33&txt=318%C3%97180&w=318&h=180"
          alt="Card image cap"
        />
        <CardBody>
          <CardTitle>Card title</CardTitle>
          <CardSubtitle>Card subtitle</CardSubtitle>
          <CardText>
            Some quick example text to build on the card title and make up the
            bulk of the card&apos;s content.
          </CardText>
          <Button>Button</Button>
        </CardBody>
      </div>
    </div>
    <h3>Form Elements</h3>
    <div className="bd-callout">
      <Form autoComplete="off">
        <FormGroup>
          <Label for="exampleEmail" className="d-none">
            Email
          </Label>
          <Input
            type="email"
            name="email"
            id="exampleEmail"
            placeholder="Email Address"
          />
        </FormGroup>
        <FormGroup>
          <Label for="examplePassword" className="d-none">
            Password
          </Label>
          <Input
            type="password"
            name="password"
            id="examplePassword"
            placeholder="Password"
          />
        </FormGroup>
        <FormGroup>
          <Label for="exampleSelect" className="d-none">
            Select
          </Label>
          <Input type="select" name="select" id="exampleSelect">
            <option value="" selected>
              Select One
            </option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="exampleSelectMulti" className="d-none">
            Select Multiple
          </Label>
          <Input
            type="select"
            name="selectMulti"
            id="exampleSelectMulti"
            multiple
          >
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="exampleText" className="d-none">
            Text Area
          </Label>
          <Input
            type="textarea"
            name="text"
            id="exampleText"
            placeholder="This is a textbox. More text goes here"
          />
        </FormGroup>
        <FormGroup>
          <div className="custom-file">
            <Label className="custom-file-label" for="exampleFile">
              <FontAwesomeIcon
                icon={['fal', 'arrow-circle-up']}
                color="#0094d5"
              />
              &nbsp; Upload File
            </Label>
            <Input
              className="custom-file-input"
              type="file"
              name="file"
              id="exampleFile"
            />
          </div>
          <FormText color="muted" className="mt-4 mb-4">
            This is some placeholder block-level help text for the above input.
            It&apos;s a bit lighter and easily wraps to a new line.
          </FormText>
        </FormGroup>
        <FormGroup tag="fieldset">
          <legend>Radio Buttons</legend>
          <FormGroup check>
            <div className="custom-control custom-radio">
              <Input
                type="radio"
                className="custom-control-input"
                name="radio1"
                id="option1"
              />
              <Label className="custom-control-label" for="option1" check>
                Option one is this and that—be sure to include why it&apos;s
                great
              </Label>
            </div>
          </FormGroup>
          <FormGroup check>
            <div className="custom-control custom-radio">
              <Input
                type="radio"
                className="custom-control-input"
                name="radio1"
                id="option2"
              />
              <Label className="custom-control-label" for="option2" check>
                Option two can be something else and selecting it will deselect
                option one
              </Label>
            </div>
          </FormGroup>
          <FormGroup check disabled>
            <div className="custom-control custom-radio">
              <Input
                type="radio"
                className="custom-control-input"
                name="radio1"
                id="option3"
                disabled
              />
              <Label className="custom-control-label" for="option3" check>
                Option three is disabled
              </Label>
            </div>
          </FormGroup>
        </FormGroup>
        <FormGroup check>
          <div className="custom-control custom-checkbox">
            <Input
              type="checkbox"
              className="custom-control-input"
              id="customCheckbox"
            />
            <Label check className="custom-control-label" for="customCheckbox">
              Check me out
            </Label>
          </div>
        </FormGroup>
        <Button className="mt-4">Submit</Button>
      </Form>
    </div>
  </div>
);

export default StyleGuide;
