import * as Yup from 'yup';

export default Yup.object().shape({
  isFederated: Yup.bool(),
  firstName: Yup.string()
    .required('Please provide first name')
    .min(2, 'Please enter a valid first name that is at least 2 characters'),
  lastName: Yup.string()
    .required('Please provide last name')
    .min(2, 'Please enter a valid last name that is at least 2 characters'),
  userName: Yup.string().required('Please provide username'),
  email: Yup.string()
    .email('Email address is not valid')
    .required('Please provide an email address'),
  password: Yup.string().when('isFederated', {
    is: false,
    then: Yup.string()
      .required('Please enter a valid password')
      .min(8, 'Please enter a valid password that is at least 8 characters')
  }),
  confirmPassword: Yup.string().when('isFederated', {
    is: false,
    then: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must much')
      .required('Password confirmation is required')
  }),
  postalCode: Yup.string()
    .required('Please provide zip code')
    .min(3, 'Please enter a valid zip code that is at least 3 characters')
});
