import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Alert } from 'reactstrap';

import { forgotPasswordConfirmAction } from '../../store/actions/auth';

import UpdatePasswordForm from '../../components/Password/Update/Form';

class ConfirmForgotPassword extends Component {
  handleSubmit = async values => {
    const { forgotPasswordConfirm, history } = this.props;
    const { code, newPassword, email } = values;

    await forgotPasswordConfirm({
      username: email,
      code: `${code}`,
      newPassword
    });
    history.push('/login');
  };

  render() {
    const {
      auth: {
        forgotPassword: { isRejected, isPending, error: { message } = {} }
      },
      match: {
        params: { code = '' }
      }
    } = this.props;

    return (
      <div>
        <Helmet>
          <title>Update Password</title>
        </Helmet>
        {isRejected && message && <Alert color="danger">{message}</Alert>}
        <h1 className="d-none">Update Password</h1>
        <div className="row justify-content-md-center">
          <div className="col-lg-6">
            <UpdatePasswordForm
              onSubmit={this.handleSubmit}
              initialValues={{ code }}
              isPending={isPending}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  forgotPasswordConfirm: params => dispatch(forgotPasswordConfirmAction(params))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ConfirmForgotPassword);
