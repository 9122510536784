import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label
} from 'reactstrap';

const Project = ({ info, members, reload }) => {
  const [modal, setModal] = useState(false);
  const [editedProject, setEditedProject] = useState(info);
  const [duplicate, setDuplicate] = useState(false);
  const toggle = () => setModal(!modal);

  const openDuplicate = () => {
    setDuplicate(true);
    toggle();
  };

  const closeModal = () => {
    toggle();
    setDuplicate(false);
  };

  useEffect(() => {
    setEditedProject(info);
  }, [info]);

  const editProject = (e, key) => {
    let { value } = e.target;
    if (key === 'teamLead') {
      value = members[e.target.value];
    }
    setEditedProject({ ...editedProject, [key]: value });
  };

  const editQuestion = (text, idx) => {
    const newQuestions = editedProject.questions;
    newQuestions[idx] = text;
    setEditedProject({
      ...editedProject,
      questions: newQuestions
    });
  };

  const addDeleteQuestion = (add, idx) => {
    if (add) {
      const newQuestions = editedProject.questions;
      newQuestions.push('');
      setEditedProject({
        ...editedProject,
        questions: newQuestions
      });
    } else {
      const newQuestions = editedProject.questions;
      newQuestions.splice(idx, 1);
      setEditedProject({
        ...editedProject,
        questions: newQuestions
      });
    }
  };

  const saveProject = pause => {
    if (!editedProject.teamLead || editedProject.teamLead.id === 0) {
      alert('Team lead must be selected');
    } else {
      const formBody = editedProject;
      formBody.channel = {
        id: process.env.REACT_APP_SLACK_CHANNEL,
        name: 'test-update'
      };
      if (pause !== undefined) formBody.active = pause;
      console.log(formBody);
      const requestOptions = {
        method: duplicate ? 'POST' : 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formBody)
      };
      if (duplicate) delete formBody._id;
      const url = duplicate
        ? `${process.env.REACT_APP_API_URL}/auth/projects`
        : `${process.env.REACT_APP_API_URL}/auth/projects/${formBody._id}`;
      return fetch(url, requestOptions)
        .then(res => res.json())
        .then(json => {
          toggle();
          reload();
        })
        .catch(err => console.error(err));
    }
  };

  const deleteProject = () => {
    const requestOptions = {
      method: 'DELETE'
    };
    const url = `${process.env.REACT_APP_API_URL}/auth/projects/${editedProject._id}`;
    return fetch(url, requestOptions)
      .then(res => {
        if (res.status === 204) {
          alert('Project successfully deleted');
          toggle();
          reload();
        }
      })
      .catch(err => console.error(err));
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>
          {duplicate
            ? `Duplicate Alert - ${info.name}`
            : `Edit Alert - ${info.name}`}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="name">Alert Title</Label>
              <Input
                type="text"
                value={editedProject.name}
                onChange={e => editProject(e, 'name')}
              />
            </FormGroup>
            <FormGroup>
              <Label for="pm">Project Manager</Label>
              <Input
                type="text"
                value={editedProject.pm}
                onChange={e => editProject(e, 'pm')}
              />
            </FormGroup>
            <FormGroup>
              <Label for="teamLead">Team Lead:</Label>
              <Input
                type="select"
                value={members.findIndex(
                  item => item.id === editedProject.teamLead.id
                )}
                onChange={e => editProject(e, 'teamLead')}
              >
                {members.map((m, idx) => {
                  return <option value={idx}>{m.name}</option>;
                })}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="dayOfWeek">Day of Week:</Label>
              <Input
                type="select"
                value={editedProject.dayOfWeek}
                onChange={e => editProject(e, 'dayOfWeek')}
              >
                <option>MON</option>
                <option>TUE</option>
                <option>WED</option>
                <option>THU</option>
                <option>FRI</option>
                <option>SAT</option>
                <option>SUN</option>
              </Input>
            </FormGroup>
            <Label>Questions:</Label>
            <br />
            {editedProject.questions.map((q, i) => {
              return (
                <FormGroup key={i}>
                  <Label onClick={() => addDeleteQuestion(false, i)} for={q.id}>
                    # {i + 1} - Click to delete
                  </Label>
                  <Input
                    onChange={e => editQuestion(e.target.value, i)}
                    type="text"
                    value={q}
                  />
                </FormGroup>
              );
            })}
            <Button onClick={() => addDeleteQuestion(true)}>
              Add Question
            </Button>
          </Form>
        </ModalBody>
        <ModalFooter>
          {duplicate ? (
            ''
          ) : (
            <>
              <Button color="primary" onClick={() => saveProject(!info.active)}>
                {info.active ? 'Pause' : 'Unpause'}
              </Button>
              <Button color="primary" onClick={deleteProject}>
                Delete
              </Button>
            </>
          )}
          <Button color="secondary" type="submit" onClick={() => saveProject()}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
      <Card className="mb-3 mt-3">
        <CardBody>
          <CardTitle tag="h5">{info.name}</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">
            Team Lead: {info.teamLead.name}
          </CardSubtitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">
            Project Manager: {info.pm}
          </CardSubtitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">
            Alert Day: {info.dayOfWeek}
          </CardSubtitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">
            Status: {info.active ? 'Active' : 'Paused'}
          </CardSubtitle>
          <Button className="mr-2" onClick={toggle}>
            Edit
          </Button>
          <Button onClick={openDuplicate}>Duplicate</Button>
        </CardBody>
      </Card>
    </div>
  );
};

export default Project;
