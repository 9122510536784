import _isNil from 'lodash/isNil';
import React, { PureComponent } from 'react';
import { Button, FormGroup, Label, Input, Media } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form, Field, ErrorMessage, getIn } from 'formik';
import validationSchema from './validationSchema';

class ProfileForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  renderInputErrors = inputName => (
    <ErrorMessage name={inputName}>
      {msg => (
        <div className="errorContainer">
          <span className="errorMessage">{msg}</span>
        </div>
      )}
    </ErrorMessage>
  );

  renderVerifiedLabel = (verified, label, id) => (
    <div className="d-flex justify-content-between mt-1">
      <FormGroup check>
        <div className="custom-control custom-checkbox">
          <Input
            type="checkbox"
            className="custom-control-input"
            id={label + id}
          />
          <Label check className="custom-control-label" for={label + id}>
            Send Notifications
          </Label>
        </div>
      </FormGroup>

      <div className="help-block">
        Verified &nbsp;
        <FontAwesomeIcon icon="check-circle" color="#5ab2ff" />
      </div>
      {/* <div className="help-block">
        Not Verified &nbsp;
        <FontAwesomeIcon icon={['fal', 'times-hexagon']} color="#ff5a5f" />
      </div> */}
    </div>
  );

  renderInput = props => {
    const {
      field,
      field: { name },
      placeholder,
      type,
      id,
      label,
      className = '',
      verified,
      disabled,
      form: { errors, touched }
    } = props;
    const hasError = getIn(errors, name) && getIn(touched, name);

    return (
      <FormGroup className={className}>
        <Input
          {...field}
          type={type}
          placeholder={placeholder}
          id={id}
          invalid={hasError}
          disabled={disabled}
        />
        {_isNil(verified) ? (
          <Label className="d-none" for={id}>
            {label}
          </Label>
        ) : (
          this.renderVerifiedLabel(verified, label, id)
        )}
        {this.renderInputErrors(name)}
      </FormGroup>
    );
  };

  renderStateSelect = props => {
    const { field, id } = props;

    return (
      <FormGroup>
        <Label className="d-none" for="state">
          State
        </Label>
        <Input {...field} type="select" id={id}>
          <option value="" disabled>
            State
          </option>
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="DC">District Of Columbia</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WA">Washington</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
        </Input>
      </FormGroup>
    );
  };

  renderSubmitButton = ({ isSubmitting }) => {
    const { isPending } = this.props;
    return (
      <Button
        disabled={isSubmitting && isPending}
        type="submit"
        color="primary"
        className="btn-block mt-4"
      >
        Save Changes
      </Button>
    );
  };

  getProfilePicUrl = (profilePictureUrl, firstName, lastName) => {
    let picUrl = profilePictureUrl;

    if (!profilePictureUrl) {
      if (firstName && lastName) {
        picUrl = `//ui-avatars.com/api/?name=${firstName}+${lastName}`;
      } else {
        picUrl = '//ui-avatars.com/api/?name=John+Doe';
      }
    }

    return picUrl;
  };

  renderFormContent = ({
    isSubmitting,
    initialValues: { isFederated, firstName, lastName }
  }) => {
    const { onAvatarChange, profilePictureUrl } = this.props;

    return (
      <Form autoComplete="off">
        <div className="d-flex justify-content-center flex-column">
          <Media
            object
            className="profile-card__avatar m-auto"
            src={this.getProfilePicUrl(profilePictureUrl, firstName, lastName)}
            alt="Profile Image"
          />
          <FormGroup>
            <div className="custom-file">
              <Label
                className="custom-file-label text-primary"
                for="chooseFile"
              >
                <FontAwesomeIcon icon={['fal', 'camera']} color="#ff0e15" />
                &nbsp; CHANGE
              </Label>
              <Input
                className="custom-file-input"
                type="file"
                name="file"
                id="chooseFile"
                onChange={onAvatarChange}
              />
            </div>
          </FormGroup>
        </div>
        <Field
          name="firstName"
          render={props =>
            this.renderInput({
              ...props,
              type: 'text',
              id: 'firstName',
              placeholder: 'First name',
              label: 'First name',
              className: 'firstName'
            })
          }
        />
        <Field
          name="lastName"
          render={props =>
            this.renderInput({
              ...props,
              type: 'text',
              id: 'lastName',
              placeholder: 'Last name',
              label: 'Last name',
              className: 'lastName'
            })
          }
        />

        <Field
          name="city"
          render={props =>
            this.renderInput({
              ...props,
              type: 'text',
              id: 'city',
              placeholder: 'City',
              label: 'City'
            })
          }
        />

        <Field
          name="state"
          render={props =>
            this.renderStateSelect({
              ...props,
              id: 'state'
            })
          }
        />

        <Field
          name="postalCode"
          render={props =>
            this.renderInput({
              ...props,
              type: 'text',
              id: 'postalCode',
              placeholder: 'Zip code',
              label: 'Zip code'
            })
          }
        />
        <Field
          name="email"
          render={props =>
            this.renderInput({
              ...props,
              disabled: isFederated,
              type: 'email',
              id: 'userEmail',
              placeholder: 'Email address',
              label: 'Email',
              verified: false
            })
          }
        />
        <Field
          name="phoneNumber"
          render={props =>
            this.renderInput({
              ...props,
              type: 'text',
              id: 'phoneNumber',
              placeholder: 'Phone number',
              label: 'Phone Number'
            })
          }
        />

        {!isFederated && (
          <>
            <Field
              name="password"
              render={props =>
                this.renderInput({
                  ...props,
                  type: 'password',
                  id: 'password',
                  placeholder: 'Current password',
                  label: 'Current password'
                })
              }
            />

            <Field
              name="newPassword"
              render={props =>
                this.renderInput({
                  ...props,
                  type: 'password',
                  id: 'newPassword',
                  placeholder: 'New password',
                  label: 'New password'
                })
              }
            />

            <Field
              name="confirmPassword"
              render={props =>
                this.renderInput({
                  ...props,
                  type: 'password',
                  id: 'confirmPassword',
                  placeholder: 'Confirm password',
                  label: 'Confirm password'
                })
              }
            />
          </>
        )}

        <Field name="isFederated" type="hidden" />

        {this.renderSubmitButton({ isSubmitting })}
      </Form>
    );
  };

  render() {
    const { onSubmit, initialData } = this.props;
    return (
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          city: '',
          state: '',
          postalCode: '',
          email: '',
          phoneNumber: '',
          password: '',
          newPassword: '',
          confirmPassword: '',
          profilePicUrl: '',
          ...initialData
        }}
        onSubmit={onSubmit}
        render={this.renderFormContent}
        validationSchema={validationSchema}
      />
    );
  }
}

export default ProfileForm;
