import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Navigation from '../Navigation';
import {
  logout,
  resetCurrentUser,
  resetCurrentSession,
  loginSlack
} from '../../store/actions/auth';
import { getUserAction } from '../../store/actions/user';

class App extends Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    signOut: PropTypes.func.isRequired,
    auth: PropTypes.shape({
      isAuthenticated: PropTypes.bool,
      currentUser: PropTypes.object
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired
  };

  async componentDidMount() {
    const { history, profile } = this.props;
    const access_token = localStorage.getItem('access_token');
    const user_id = localStorage.getItem('user_id');

    console.log(profile.current.user);
    try {
      if (
        !Object.entries(profile.current.user).length &&
        (access_token, user_id)
      ) {
        console.log(2);
        await this.props.login();
      } else {
        history.push('/');
      }
    } catch (err) {
      console.error(err);
      // do nothing for now. Just falls back to
      // normal trending functionality.
      // throw err;
    }
  }

  /**
   * Handle logout click
   *
   * @memberof App
   */
  handleLogout = async event => {
    event.preventDefault();
    const { signOut, history } = this.props;
    await signOut();
    history.push('/');
  };

  render() {
    const {
      children,
      auth: {
        currentUser: { isAuthenticated, data }
      },
      profile: {
        fetch: {
          data: { data: profileData }
        }
      }
    } = this.props;

    return (
      <div>
        <Helmet titleTemplate="%s - Inventive" defaultTitle="Inventive" />
        <Navigation
          role="navigation"
          isAuthenticated={isAuthenticated}
          userData={data}
          profileData={profileData}
          onLogout={this.handleLogout}
        />
        <main id="content" className="container">
          {children}
        </main>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  getUser: (token, user) => dispatch(getUserAction(token, user)),
  signOut: () => {
    dispatch(resetCurrentUser());
    dispatch(resetCurrentSession());
    dispatch(logout());
  },
  login: () => dispatch(loginSlack())
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(App);
