import LogRocket from 'logrocket';
import api from '../../lib/api';
import { createProfileAction, getUserAction } from './user';
import {
  AUTH_SIGN_IN,
  AUTH_SIGN_UP,
  AUTH_SIGN_UP_CONFIRM,
  AUTH_CURRENT_SESSION,
  AUTH_CURRENT_SESSION_RESET,
  AUTH_CURRENT_USER,
  AUTH_CURRENT_USER_RESET,
  AUTH_LOGOUT,
  AUTH_FORGOT_PASSWORD,
  AUTH_FORGOT_PASSWORD_CONFIRM,
  AUTH_CREATE_ACCOUNT,
  AUTH_USER_DELETE,
  AUTH_CHANGE_PASSWORD,
  AUTH_UPDATE_ATTRS,
  AUTH_SLACK_LOGIN_PENDING,
  AUTH_SLACK_LOGIN_REJECTED,
  AUTH_SLACK_LOGIN_FULFILLED,
  LOGOUT_SLACK
} from './types';

export const signInAction = credentials => {
  const { username } = credentials;
  LogRocket.identify(username, {});

  return {
    type: AUTH_SIGN_IN,
    payload: api.auth.signIn(credentials)
  };
};

export const signUpAction = params => ({
  type: AUTH_SIGN_UP,
  payload: api.auth.signUp(params)
});

export const confirmSignUpAction = (username, code) => {
  LogRocket.identify(username, {});
  return {
    type: AUTH_SIGN_UP_CONFIRM,
    payload: api.auth.confirmSignUp(username, code)
  };
};

export const currentSessionAction = () => ({
  type: AUTH_CURRENT_SESSION,
  payload: api.auth.currentSession()
});

export const getAuthenticatedUser = () => ({
  type: AUTH_CURRENT_USER,
  payload: api.auth.currentUser()
});

export const loginSlack = code => async dispatch => {
  const access_token = localStorage.getItem('access_token');
  const user_id = localStorage.getItem('user_id');
  if (user_id && access_token) {
    try {
      api.auth
        .verifyUser(access_token)
        .then(r => {
          if (r.ok) {
            dispatch(getUserAction(access_token, user_id));
            dispatch({
              type: AUTH_SLACK_LOGIN_FULFILLED
              // payload: api.auth.signIn(credentials)
            });
          } else {
            dispatch({
              type: AUTH_SLACK_LOGIN_REJECTED,
              payload: r.error
            });
          }
        })
        .catch(err => {
          dispatch({
            type: AUTH_SLACK_LOGIN_REJECTED,
            payload: err
          });
        });
    } catch (e) {
      dispatch({
        type: AUTH_SLACK_LOGIN_REJECTED,
        payload: 'Error making API call'
      });
    }
  } else {
    dispatch({
      type: AUTH_SLACK_LOGIN_PENDING
      // payload: api.auth.signIn(credentials)
    });
    try {
      api.auth
        .loginSlack(code)
        .then(r => {
          if (r.ok) {
            localStorage.setItem('user_id', r.authed_user.id);
            localStorage.setItem('access_token', r.access_token);
            dispatch({
              type: AUTH_SLACK_LOGIN_FULFILLED
              // payload: api.auth.signIn(credentials)
            });
            dispatch(getUserAction(r.access_token, r.authed_user.id));
          } else {
            dispatch({
              type: AUTH_SLACK_LOGIN_REJECTED,
              payload: r.error
            });
          }
          return r;
        })
        .catch(err => {
          dispatch({
            type: AUTH_SLACK_LOGIN_REJECTED,
            payload: err
          });
        });
    } catch {
      dispatch({
        type: AUTH_SLACK_LOGIN_REJECTED,
        payload: 'Error making API call'
      });
    }
  }
};

export const logoutSlack = () => ({
  type: LOGOUT_SLACK
});

export const logout = () => ({
  type: AUTH_LOGOUT,
  payload: api.auth.logout()
});

export const forgotPasswordAction = username => ({
  type: AUTH_FORGOT_PASSWORD,
  payload: api.auth.forgotPassword(username)
});

export const forgotPasswordConfirmAction = params => ({
  type: AUTH_FORGOT_PASSWORD_CONFIRM,
  payload: api.auth.forgotPasswordConfirm(params)
});

export const resetCurrentUser = () => ({
  type: AUTH_CURRENT_USER_RESET
});

export const resetCurrentSession = () => ({
  type: AUTH_CURRENT_SESSION_RESET
});

export const changePasswordAction = (
  cognitoUser,
  oldPassword,
  newPassword
) => ({
  type: AUTH_CHANGE_PASSWORD,
  payload: api.auth.changePassword(cognitoUser, oldPassword, newPassword)
});

export const updateAuthAttributesAction = (cognitoUser, attributes) => ({
  type: AUTH_UPDATE_ATTRS,
  payload: api.auth.updateAuthAttributes(cognitoUser, attributes)
});

export const deleteAuthUserAction = cognitoUser => ({
  type: AUTH_USER_DELETE,
  payload: new Promise((resolve, reject) => {
    cognitoUser.deleteUser((err, data) => (err ? reject(err) : resolve(data)));
  })
});

/**
 * Create account action.
 * Complex action that will signup in cognito and create a profile in mongo
 *
 * @param {*} params
 */
export const createAccountAction = params => dispatch =>
  dispatch({
    type: AUTH_CREATE_ACCOUNT,
    payload: async () => {
      const {
        userName: username,
        password,
        email,
        firstName,
        lastName
      } = params;

      let userId = params.id;

      if (params.isFederated) {
        await dispatch(
          updateAuthAttributesAction(null, {
            email,
            name: `${firstName} ${lastName}`
          })
        );
      } else {
        const { value: { userSub } = {} } = await dispatch(
          signUpAction({ username, password, email })
        );

        userId = userSub;
      }

      await dispatch(
        createProfileAction({
          ...params,
          externalId: userId
        })
      );
    }
  });
