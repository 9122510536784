import * as Yup from 'yup';

export default Yup.object().shape({
  firstName: Yup.string()
    .required('Please provide first name')
    .min(2, 'Please enter a valid first name that is at least 2 characters'),
  lastName: Yup.string()
    .required('Please provide last name')
    .min(2, 'Please enter a valid last name that is at least 3 characters'),
  email: Yup.string()
    .email('Email address is not valid')
    .required('Please provide an email address'),
  password: Yup.string().min(8, 'Password should be at least 8 characters'),
  newPassword: Yup.string().min(8, 'Password should be at least 8 characters'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('newPassword'), null],
    'Passwords must much'
  ),
  phoneNumber: Yup.string().matches(
    /(\+\d+)?(?:[- .])?(\d\d\d)(?:[- .])?(\d\d\d)(?:[- .])?(\d\d\d\d)/,
    {
      message: 'Phone number should be in +15556667777 format',
      excludeEmptyString: true
    }
  ),
  postalCode: Yup.string()
    .required('Please provide zip code')
    .min(3, 'Please enter a valid zip code that is at least 3 characters')
    .max(5, 'Please enter a valid zip code that is up to 5 characters')
});
