/* eslint-disable react/jsx-closing-bracket-location */
import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logoutSlack } from '../../store/actions/auth';

import logo from '../../images/logo.svg';
import './style.scss';

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.menuToggle = this.menuToggle.bind(this);
    this.state = {
      isMenuToggleOpen: false,
      active: false
    };
  }

  handleLogout = event => {
    this.props.signOut();
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_id');
    this.props.history.push('/');
  };

  getProfilePicUrl = profileData => {
    const defaultPicUrl = '//ui-avatars.com/api/?name=John+Doe';
    if (!profileData) {
      return defaultPicUrl;
    }

    if (profileData.profilePicUrl) {
      return profileData.profilePicUrl;
    }

    if (profileData.firstName && profileData.lastName) {
      return `//ui-avatars.com/api/?name=${profileData.firstName}+${profileData.lastName}`;
    }

    return defaultPicUrl;
  };

  menuToggle() {
    const { isMenuToggleOpen, active } = this.state;
    this.setState({
      isMenuToggleOpen: !isMenuToggleOpen,
      active: !active
    });
  }

  render() {
    const { isAuthenticated } = this.props;

    const { isMenuToggleOpen, active } = this.state;

    return (
      <Navbar color="inverse" light expand="lg">
        <div className="container">
          <NavbarToggler
            data-target="menu"
            aria-label="Menu"
            value="Menu"
            onClick={this.menuToggle}
            className={active ? 'active' : ''}
          >
            <span className="bar upper" />
            <span className="bar middle" />
            <span className="bar lower" />
          </NavbarToggler>
          <NavbarBrand href="/">
            <img src={logo} width={80} alt="Inventive" />
          </NavbarBrand>
          <Collapse id="menu" isOpen={isMenuToggleOpen} navbar>
            <Nav navbar className="ml-auto">
              <NavItem>
                <NavLink href="#content" className="sr-only">
                  Skip to main content
                </NavLink>
              </NavItem>
              {!isAuthenticated && (
                <>
                  <div className="d-none d-lg-flex">
                    <NavItem>
                      <NavLink
                        href={`https://slack.com/oauth/v2/authorize?scope=chat:write,im:write,users:read&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=https%3A%2F%2F${process.env.REACT_APP_DOMAIN_NAME}%2Flogin`}
                      >
                        Login with Slack
                      </NavLink>
                    </NavItem>
                  </div>
                  <div className="d-flex d-lg-none flex-column align-items-start">
                    <NavItem>
                      <NavLink
                        href={`https://slack.com/oauth/v2/authorize?scope=chat:write,im:write,users:read&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=https%3A%2F%2F${process.env.REACT_APP_DOMAIN_NAME}%2Flogin`}
                      >
                        Login with Slack
                      </NavLink>
                    </NavItem>
                  </div>
                </>
              )}

              {isAuthenticated && (
                <>
                  <div className="d-none d-lg-flex">
                    <NavItem>
                      <NavLink onClick={this.handleLogout}>Logout</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/form">Form</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/projects">Projects</NavLink>
                    </NavItem>
                  </div>
                  <div className="d-flex d-lg-none flex-column align-items-start">
                    <NavItem>
                      <NavLink onClick={this.handleLogout}>Logout</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/form">Form</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/projects">Projects</NavLink>
                    </NavItem>
                  </div>
                </>
              )}
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  signOut: () => dispatch(logoutSlack())
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(Navigation);
