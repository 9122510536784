import React, { PureComponent } from 'react';
import { Formik, Form, Field, ErrorMessage, getIn } from 'formik';
import { Button, FormGroup, Label, Input } from 'reactstrap';
import validationSchema from './validationSchema';

class UpdatePasswordForm extends PureComponent {
  renderInput = props => {
    const {
      field,
      field: { name },
      placeholder,
      type,
      id,
      label,
      disabled = false,
      form: { errors, touched }
    } = props;
    const hasError = getIn(errors, name) && getIn(touched, name);

    return (
      <FormGroup className="col-12">
        <Label for={id}>{label}</Label>
        <Input
          {...field}
          type={type}
          placeholder={placeholder}
          id={id}
          invalid={hasError}
          disabled={disabled}
        />
        {this.renderInputErrors(name)}
      </FormGroup>
    );
  };

  renderInputErrors = inputName => (
    <ErrorMessage name={inputName}>
      {msg => (
        <div className="errorContainer">
          <span className="errorMessage">{msg}</span>
        </div>
      )}
    </ErrorMessage>
  );

  renderSubmitButton = ({ isSubmitting }) => {
    const { isPending } = this.props;
    return (
      <Button
        disabled={isSubmitting && isPending}
        type="submit"
        color="primary"
        className="btn-block mb-3"
      >
        Update password
      </Button>
    );
  };

  renderForm = ({ isSubmitting }) => {
    const {
      initialValues: { code = '' }
    } = this.props;

    return (
      <Form autoComplete="off">
        <Field
          name="code"
          render={props =>
            this.renderInput({
              ...props,
              type: 'text',
              id: 'resetCode',
              placeholder: 'Reset password code',
              label: 'Code',
              disabled: code !== null && code !== ''
            })
          }
        />

        <Field
          name="email"
          render={props =>
            this.renderInput({
              ...props,
              type: 'email',
              id: 'email',
              placeholder: 'Email',
              label: 'Email'
            })
          }
        />

        <Field
          name="newPassword"
          render={props =>
            this.renderInput({
              ...props,
              type: 'password',
              id: 'newPassword',
              placeholder: 'New Password',
              label: 'New password'
            })
          }
        />

        <Field
          name="newPasswordConfirm"
          render={props =>
            this.renderInput({
              ...props,
              type: 'password',
              id: 'confirmPassword',
              placeholder: 'Confirm New Password',
              label: 'Confirm new password'
            })
          }
        />
        {this.renderSubmitButton({ isSubmitting })}
      </Form>
    );
  };

  render() {
    const {
      onSubmit,
      initialValues: { code }
    } = this.props;

    return (
      <Formik
        initialValues={{
          code,
          newPassword: '',
          newPasswordConfirm: ''
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        render={this.renderForm}
      />
    );
  }
}

export default UpdatePasswordForm;
