import { DEFAULT_ASYNC_STATE } from '../constants';
import {
  AUTH_SLACK_LOGIN_PENDING,
  AUTH_SLACK_LOGIN_REJECTED,
  AUTH_SLACK_LOGIN_FULFILLED,
  LOGOUT_SLACK
} from '../actions/types';

const DEFAULT_STATE = {
  ...DEFAULT_ASYNC_STATE,
  isAuthenticated: false,
  data: {}
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case AUTH_SLACK_LOGIN_PENDING:
      return {
        ...DEFAULT_STATE,
        isPending: true
      };
    case AUTH_SLACK_LOGIN_REJECTED:
      return {
        ...DEFAULT_STATE,
        isRejected: true,
        error: action.payload
      };
    case AUTH_SLACK_LOGIN_FULFILLED:
      return {
        ...DEFAULT_STATE,
        isFulfilled: true,
        isAuthenticated: true
      };
    case LOGOUT_SLACK:
      return {
        ...DEFAULT_STATE,
        isAuthenticated: false
      };
    default:
      return state;
  }
};
