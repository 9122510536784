/**
 * Protected route component
 *
 * Should be used as regular route <Route ... />
 * If user is not logged then it will redirect to login screen
 */
import React, { PureComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { NON_AUTHORIZED_REDIRECT_URL } from '../../store/constants';

class ProtectedRoute extends PureComponent {
  renderComponent = (props, Component, isLoggedIn) =>
    isLoggedIn ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: NON_AUTHORIZED_REDIRECT_URL,
          state: { from: props.location }
        }}
      />
    );

  render() {
    const {
      component: Component,
      auth: {
        currentUser: { isAuthenticated, isFulfilled, isRejected }
      },
      ...rest
    } = this.props;

    return (
      (isFulfilled || isRejected) && (
        <Route
          {...rest}
          render={props =>
            this.renderComponent(props, Component, isAuthenticated)
          }
        />
      )
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(ProtectedRoute);
