import { combineReducers } from 'redux';

import authSignUp from './authSignUp';
import authSignUpConfirm from './authSignUpConfirm';
import authCreateAccount from './authCreateAccount';
import authUserDelete from './authUserDelete';
// import authCurrentUser from './authCurrentUser';
import authSlackLogin from './authSlackLogin';
import authCurrentSession from './authCurrentSession';
import authPasswordReducer from './authPassword';
import authChangePassword from './authChangePassword';
import authLogout from './authLogout';
import authUpdateAttributes from './authUpdateAttributes';
import getSlackUser from './getSlackUser';
import userProfileFetchAll from './userProfileFetchAll';
import userProfileFetch from './userProfileFetch';
import userProfileCreate from './userProfileCreate';
import userProfileUpdate from './userProfileUpdate';
import userUploadImage from './userUploadImage';

export default combineReducers({
  auth: combineReducers({
    signUp: authSignUp,
    signUpConfirm: authSignUpConfirm,
    currentUser: authSlackLogin,
    currentSession: authCurrentSession,
    forgotPassword: authPasswordReducer,
    changePassword: authChangePassword,
    createAccount: authCreateAccount,
    logout: authLogout,
    deleteUser: authUserDelete,
    updateAttributes: authUpdateAttributes
  }),
  profile: combineReducers({
    all: userProfileFetchAll,
    fetch: userProfileFetch,
    current: getSlackUser,
    create: userProfileCreate,
    update: userProfileUpdate,
    avatar: userUploadImage
  })
});
