import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Alert } from 'reactstrap';

import { forgotPasswordAction } from '../../store/actions/auth';

import ForgotPasswordForm from '../../components/Password/Forgot/Form';

class ForgotPassword extends Component {
  handleSubmit = async values => {
    const { forgotPassword, history } = this.props;
    const { email } = values;
    await forgotPassword(email);
    history.push('/forgot-password-confirm');
  };

  render() {
    const {
      auth: {
        forgotPassword: { isRejected, isPending, error: { message } = {} }
      }
    } = this.props;

    return (
      <div>
        <Helmet>
          <title>Forgot Password</title>
        </Helmet>
        {isRejected && message && <Alert color="danger">{message}</Alert>}
        <h1 className="d-none">Forgot Password?</h1>
        <div className="row justify-content-md-center">
          <div className="col-lg-6">
            <ForgotPasswordForm
              onSubmit={this.handleSubmit}
              isPending={isPending}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  forgotPassword: username => dispatch(forgotPasswordAction(username))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ForgotPassword);
