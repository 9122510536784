import _isEmpty from 'lodash/isEmpty';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Alert } from 'reactstrap';
import { Helmet } from 'react-helmet';

import ProfileForm from '../../components/Profile/Form';
import parsePhoneNumber from '../../lib/phoneNumbers';
import {
  fetchProfileAction,
  updateProfileAction,
  uploadImageAction
} from '../../store/actions/user';
import {
  changePasswordAction,
  updateAuthAttributesAction
} from '../../store/actions/auth';

class EditProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {
    const {
      fetchProfile,
      authCurrentUser: {
        data: { attributes: { sub } = {}, id = null }
      }
    } = this.props;

    await fetchProfile(sub || id);
  }

  handleSubmit = async values => {
    const {
      updateProfile,
      changePassword,
      updateAuthAttributes,
      uploadImage,
      authCurrentUser: { data: user },
      profile: {
        update: {
          data: { data: { updated: { version } = {} } = {} }
        }
      }
    } = this.props;

    const {
      externalId,
      password,
      newPassword,
      email,
      phoneNumber,
      profilePicUrl,
      version: currentVersion,
      ...profileData
    } = values;

    const userData = user.attributes || user;
    const { email: currentEmail, phone_number: currentPhoneNumber } = userData;

    if (password && newPassword) {
      await changePassword(user, password, newPassword);
    }

    let attrs = {};
    let extraData = {};

    // Upload the new profile picture to Cloudinary.
    const { profilePicture } = this.state;
    if (profilePicture) {
      const result = await uploadImage(profilePicture);
      extraData = { ...extraData, profilePicUrl: result.value.data.url };
    }

    if (email !== currentEmail) {
      attrs = { ...attrs, email };
    }

    const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    if (parsedPhoneNumber !== currentPhoneNumber) {
      attrs = { ...attrs, phone_number: parsedPhoneNumber };
    }

    if (parsedPhoneNumber || currentPhoneNumber) {
      extraData = {
        ...extraData,
        phoneNumber: parsedPhoneNumber || currentPhoneNumber
      };
    }

    if (_isEmpty(attrs)) {
      await updateAuthAttributes(user, attrs);
    }

    await updateProfile(externalId, {
      email,
      ...profileData,
      ...extraData,
      version: version || currentVersion
    });
  };

  handleAvatarChange = async e => {
    try {
      const {
        currentTarget: { files }
      } = e;

      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.setState({ profilePicture: reader.result });
      });

      reader.readAsDataURL(files[0]);
      return files[0];
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  render() {
    const {
      profile: {
        fetch: {
          data: { data: profileData },
          isFulfilled
        },
        update: {
          isFulfilled: profileUpdated,
          isRejected: profileRejected,
          error: { message: profileRejectMessage } = {}
        }
      },
      authUpdateAttributes: {
        isRejected: authAttrsRejected,
        error: { message: authAttrsRejectedMessage } = {}
      },
      authChangePassword: {
        isRejected: authChangePasswordRejected,
        error: { message: authChangePasswordRejectedMessage } = {}
      },
      authCurrentUser: {
        data: { name: isFederated }
      }
    } = this.props;

    const { profilePicture } = this.state;
    const { profilePicUrl } = profileData || { profilePicUrl: '' };

    return (
      <div>
        {isFulfilled && (
          <Helmet>
            <title>
              {`Edit ${profileData.firstName} ${profileData.lastName}'s Profile`}
            </title>
            <meta
              name="description"
              content={`Edit all of ${profileData.firstName} ${profileData.lastName}'s profile information.`}
            />
          </Helmet>
        )}
        {profileRejected && profileRejectMessage && (
          <Alert color="danger">{profileRejectMessage}</Alert>
        )}
        {authAttrsRejected && authAttrsRejectedMessage && (
          <Alert color="danger">{authAttrsRejectedMessage}</Alert>
        )}
        {authChangePasswordRejected && authChangePasswordRejectedMessage && (
          <Alert color="danger">{authChangePasswordRejectedMessage}</Alert>
        )}

        {profileUpdated && <Alert color="success">Profile updated</Alert>}

        {isFulfilled && (
          <div className="row justify-content-md-center">
            <div className="col-lg-6">
              <ProfileForm
                onSubmit={this.handleSubmit}
                onAvatarChange={this.handleAvatarChange}
                initialData={{ ...profileData, isFederated }}
                profilePictureUrl={profilePicture || profilePicUrl}
                isFederated={isFederated}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authCurrentUser: state.auth.currentUser,
  authUpdateAttributes: state.auth.updateAttributes,
  authChangePassword: state.auth.changePassword,
  profile: state.profile
});

const mapDispatchToProps = dispatch => ({
  uploadImage: async image => dispatch(uploadImageAction(image)),
  fetchProfile: id => dispatch(fetchProfileAction(id)),
  changePassword: (user, oldPassword, newPassword) =>
    dispatch(changePasswordAction(user, oldPassword, newPassword)),
  updateProfile: (id, profileData) =>
    dispatch(updateProfileAction(id, profileData)),
  updateAuthAttributes: (user, attrs) =>
    dispatch(updateAuthAttributesAction(user, attrs))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  EditProfile
);
