import React, { PureComponent } from 'react';
import { Button, FormGroup, Label, Input, Media } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form, Field, ErrorMessage, getIn } from 'formik';
import validationSchema from './validationSchema';

class SignUpForm extends PureComponent {
  renderInputErrors = inputName => (
    <ErrorMessage name={inputName}>
      {msg => (
        <div className="errorContainer">
          <span className="errorMessage">{msg}</span>
        </div>
      )}
    </ErrorMessage>
  );

  renderInput = props => {
    const {
      field,
      field: { name },
      placeholder,
      type,
      id,
      label,
      className = '',
      form: { errors, touched },
      disabled
    } = props;
    const hasError = getIn(errors, name) && getIn(touched, name);

    return (
      <FormGroup className={className || 'col-12'}>
        <Label for={id} className="d-none">
          {label}
        </Label>
        <Input
          {...field}
          type={type}
          placeholder={placeholder}
          id={id}
          invalid={hasError}
          disabled={disabled}
        />
        {this.renderInputErrors(name)}
      </FormGroup>
    );
  };

  renderSubmitButton = ({ isSubmitting }) => {
    const { isPending } = this.props;
    return (
      <div className="fixed-bottom p-4">
        <Button
          disabled={isSubmitting && isPending}
          type="submit"
          color="primary"
          className="btn-block mb-3 mt-3"
        >
          Sign Up
        </Button>
      </div>
    );
  };

  renderFormContent = ({
    isSubmitting,
    initialValues: { firstName, lastName, isFederated }
  }) => {
    const { onAvatarChange, profilePictureUrl } = this.props;
    let firstNameForPic = firstName;
    let lastNameForPic = lastName;

    if (!firstName && !lastName) {
      firstNameForPic = 'John';
      lastNameForPic = 'Doe';
    }

    return (
      <Form className="row">
        <div className="col-12 text-center">
          <div className="d-flex justify-content-center flex-column">
            <Media
              object
              className="profile-card__avatar m-auto"
              src={
                profilePictureUrl ||
                `//ui-avatars.com/api/?name=${firstNameForPic}+${lastNameForPic}`
              }
              alt="Profile Image"
            />
            <FormGroup>
              <div className="custom-file">
                <Label
                  className="custom-file-label text-primary"
                  for="chooseFile"
                >
                  <FontAwesomeIcon icon={['fal', 'camera']} color="#ff0e15" />
                  &nbsp; ADD
                </Label>
                <Input
                  className="custom-file-input"
                  type="file"
                  name="file"
                  id="chooseFile"
                  onChange={onAvatarChange}
                />
              </div>
            </FormGroup>
          </div>
        </div>
        <div className="col-12">
          <Field
            name="firstName"
            render={props =>
              this.renderInput({
                ...props,
                type: 'text',
                id: 'firstName',
                placeholder: 'First name',
                label: 'First name',
                className: 'firstName'
              })
            }
          />

          <Field
            name="lastName"
            render={props =>
              this.renderInput({
                ...props,
                type: 'text',
                id: 'lastName',
                placeholder: 'Last name',
                label: 'Last name*',
                className: 'lastName'
              })
            }
          />
        </div>
        <Field
          name="userName"
          render={props =>
            this.renderInput({
              ...props,
              type: 'text',
              id: 'userName',
              placeholder: 'Username',
              label: 'Username*'
            })
          }
        />

        <Field
          name="email"
          render={props =>
            this.renderInput({
              ...props,
              disabled: isFederated,
              type: 'email',
              id: 'userEmail',
              placeholder: 'Email address',
              label: 'Email'
            })
          }
        />

        {!isFederated && (
          <>
            <Field
              name="password"
              render={props =>
                this.renderInput({
                  ...props,
                  type: 'password',
                  id: 'userPassword',
                  placeholder: 'Password',
                  label: 'Password'
                })
              }
            />

            <Field
              name="confirmPassword"
              render={props =>
                this.renderInput({
                  ...props,
                  type: 'password',
                  id: 'confirmPassword',
                  placeholder: 'Confirm password',
                  label: 'Confirm password'
                })
              }
            />
          </>
        )}

        <Field
          name="postalCode"
          render={props =>
            this.renderInput({
              ...props,
              type: 'text',
              id: 'postalCode',
              placeholder: 'Zip code',
              label: 'Zip code'
            })
          }
        />

        <Field name="isFederated" type="hidden" />

        <div className="container">
          {this.renderSubmitButton({ isSubmitting })}
        </div>
      </Form>
    );
  };

  render() {
    const { onSubmit, initialData } = this.props;
    return (
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          userName: '',
          email: '',
          password: '',
          confirmPassword: '',
          postalCode: '',
          ...initialData
        }}
        onSubmit={onSubmit}
        render={this.renderFormContent}
        validationSchema={validationSchema}
      />
    );
  }
}

export default SignUpForm;
