import api from '../../lib/api';
import {
  USER_PROFILE_FETCH_ALL,
  USER_PROFILE_FETCH,
  USER_PROFILE_CREATE,
  USER_PROFILE_UPDATE,
  USER_CURRENT_PROFILE_FETCH,
  USER_UPLOAD_IMAGE,
  GET_SLACK_USER_FULFILLED,
  GET_SLACK_USER_PENDING,
  GET_SLACK_USER_REJECTED
} from './types';

export const fetchAllProfilesAction = () => ({
  type: USER_PROFILE_FETCH_ALL,
  payload: api.user.getAllProfiles()
});

export const fetchProfileAction = id => ({
  type: USER_PROFILE_FETCH,
  payload: api.user.getProfile(id)
});

export const getUserAction = (token, user) => async dispatch => {
  dispatch({
    type: GET_SLACK_USER_PENDING
    // payload: api.auth.signIn(credentials)
  });
  try {
    api.auth
      .getUser(token, user)
      .then(r => {
        if (r.ok) {
          dispatch({
            type: GET_SLACK_USER_FULFILLED,
            payload: r
          });
        } else {
          dispatch({
            type: GET_SLACK_USER_REJECTED,
            payload: r.error
          });
        }
        return r;
      })
      .catch(err => {
        dispatch({
          type: GET_SLACK_USER_REJECTED,
          payload: err
        });
      });
  } catch {
    dispatch({
      type: GET_SLACK_USER_REJECTED,
      payload: 'Error making API call'
    });
  }
};

export const createProfileAction = params => ({
  type: USER_PROFILE_CREATE,
  payload: api.user.createProfile(params)
});

export const updateProfileAction = (id, params) => ({
  type: USER_PROFILE_UPDATE,
  payload: api.user.updateProfile(id, params)
});

export const uploadImageAction = file => ({
  type: USER_UPLOAD_IMAGE,
  payload: api.user.uploadImage(file)
});

/**
 * Same as fetch profile action but uses a different action type
 * And different reducer
 */
export const fetchCurrentUserProfileAction = id => ({
  type: USER_CURRENT_PROFILE_FETCH,
  payload: api.user.getProfile(id)
});
