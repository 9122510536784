import _isEmpty from 'lodash/isEmpty';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { Helmet } from 'react-helmet';

import SignUpForm from '../../components/SignUp/Form/SignUp';
import withLoader from '../../components/HOC/withLoader';

import { uploadImageAction } from '../../store/actions/user';

import {
  createAccountAction,
  deleteAuthUserAction
} from '../../store/actions/auth';

import {
  SUCCESSFUL_SIGNUP_REDIRECT,
  SUCCESSFUL_SIGNUP_FEDERATED_REDIRECT
} from '../../store/constants';

class CreateAccount extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleSubmit = async values => {
    const { createAccount, uploadImage, history } = this.props;
    let accountData = values;

    try {
      // Upload the new profile picture to Cloudinary.
      const { profilePicture } = this.state;
      if (profilePicture) {
        const result = await uploadImage(profilePicture);

        accountData = Object.assign(accountData, {
          profilePicUrl: result.value.data.url
        });
      }

      await createAccount(accountData);

      accountData.isFederated
        ? history.push(SUCCESSFUL_SIGNUP_FEDERATED_REDIRECT)
        : history.push(SUCCESSFUL_SIGNUP_REDIRECT);
    } catch (err) {
      // TODO: Think on how to delete not authenticated user properly
      // this.rollbackCognitoSigUp();
      console.log(err);
      throw err;
    }
  };

  /**
   * In case cognito user was successfully signed
   * And profile creation fail - roll back cognito user signup
   *
   * @memberof CreateAccount
   */
  rollbackCognitoSigUp = () => {
    const { deleteAuthUser, signUpData: { user } = {} } = this.props;
    if (!_isEmpty(user)) {
      deleteAuthUser(user);
    }
  };

  /**
   * Prepare user data object for the sign-up form
   * Especially useful for the federated sign-up
   *
   * @memberof CreateAccount
   */
  prepareInitialUserData = userData => {
    let initialUserData = { isFederated: false };

    if (userData.attributes && userData.attributes.name) {
      const {
        username,
        attributes: { name, sub, email }
      } = userData;

      const [firstName, lastName] = name.split(' ');
      initialUserData = {
        isFederated: true,
        id: sub,
        firstName,
        lastName,
        userName: username,
        email
      };
    }

    return initialUserData;
  };

  handleAvatarChange = async e => {
    try {
      const {
        currentTarget: { files }
      } = e;

      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.setState({ profilePicture: reader.result });
      });

      reader.readAsDataURL(files[0]);
      return files[0];
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  render() {
    const {
      auth: {
        createAccount: { isRejected, isPending, error: { message } = {} },
        currentUser: { data: userData } = {}
      },
      renderLoader
    } = this.props;

    const { profilePicture: profilePicUrl } = this.state;

    const initialUserData = this.prepareInitialUserData(userData);

    return (
      <div>
        <Helmet>
          <title>Create an Account</title>
        </Helmet>
        {isRejected && message && <Alert color="danger">{message}</Alert>}
        {isPending && renderLoader()}
        <h1 className="d-none">Create Account</h1>
        <div className="row justify-content-md-center">
          <div className="col-lg-6">
            <SignUpForm
              isPending={isPending}
              onSubmit={this.handleSubmit}
              initialData={initialUserData}
              onAvatarChange={this.handleAvatarChange}
              profilePictureUrl={profilePicUrl}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  signUpData: state.auth.signUp.data
});

const mapDispatchToProps = dispatch => ({
  uploadImage: async image => dispatch(uploadImageAction(image)),
  createAccount: params => dispatch(createAccountAction(params)),
  deleteAuthUser: cognitoUser => dispatch(deleteAuthUserAction(cognitoUser))
});

export default compose(
  withLoader,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CreateAccount);
