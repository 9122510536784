export const AUTH_SIGN_IN = 'AUTH_SIGN_IN';
export const AUTH_SIGN_UP = 'AUTH_SIGN_UP';
export const AUTH_SIGN_UP_CONFIRM = 'AUTH_SIGN_UP_CONFIRM';
export const AUTH_CREATE_ACCOUNT = 'AUTH_CREATE_ACCOUNT';
export const AUTH_USER_DELETE = 'AUTH_USER_DELETE';

export const AUTH_CURRENT_SESSION = 'AUTH_CURRENT_SESSION';
export const AUTH_CURRENT_SESSION_RESET = 'AUTH_CURRENT_SESSION_RESET';

export const AUTH_CURRENT_USER = 'AUTH_CURRENT_USER';
export const AUTH_CURRENT_USER_RESET = 'AUTH_CURRENT_USER_RESET';
export const AUTH_UPDATE_ATTRS = 'AUTH_UPDATE_ATTRS';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD';
export const AUTH_FORGOT_PASSWORD_CONFIRM = 'AUTH_FORGOT_PASSWORD_CONFIRM';
export const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';

export const USER_PROFILE_FETCH_ALL = 'USER_PROFILE_FETCH_ALL';
export const USER_PROFILE_FETCH = 'USER_PROFILE_FETCH';
export const USER_PROFILE_CREATE = 'USER_PROFILE_CREATE';
export const USER_PROFILE_UPDATE = 'USER_PROFILE_UPDATE';
export const USER_CURRENT_PROFILE_FETCH = 'USER_CURRENT_PROFILE_FETCH';
export const USER_UPLOAD_IMAGE = 'USER_UPLOAD_IMAGE';

export const AUTH_SLACK_LOGIN_FULFILLED = 'AUTH_SLACK_LOGIN_FULFILLED';
export const AUTH_SLACK_LOGIN_REJECTED = 'AUTH_SLACK_LOGIN_REJECTED';
export const AUTH_SLACK_LOGIN_PENDING = 'AUTH_SLACK_LOGIN_PENDING';
export const GET_SLACK_USER_FULFILLED = 'GET_SLACK_USER_FULFILLED';
export const GET_SLACK_USER_REJECTED = 'GET_SLACK_USER_REJECTED';
export const GET_SLACK_USER_PENDING = 'GET_SLACK_USER_PENDING';
export const LOGOUT_SLACK = 'LOGOUT_SLACK';
